
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Product</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Product</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="Product">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'ProductList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-row">
                  <div class="form-group col-6 required">
                    <label class="control-label">Name</label>
                    <input type="text" id="name" name="name" v-bind:class="{'has-error' : errors.has('name')}" v-validate="'required'" v-model="value.name" class="form-control name" placeholder="Name">
                    <div class="help text-danger" v-show="errors.has('name')">
                      {{ errors.first("name") }}
                    </div>
                  </div>
                  <div class="form-group col-6 required">
                    <label class="control-label">Type</label>
                    <select class="form-control" style="width: 100%;" v-bind:class="{'has-error' : errors.has('type')}" v-validate="'required'" name="type" v-model="value.type" id="type">
                      <option value="Product">Product</option>
                      <option value="Package">Package</option>
                      <option value="Add-On">Add-On</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('type')">
                      {{ errors.first("type") }}
                    </div>
                  </div>
                  <div class="form-group col-6 required">
                    <label class="control-label">Category</label>
                    <select id="category_id" v-model="value.category_id" v-bind:class="{'has-error' : errors.has('category_id')}" v-validate="'required'" class="form-control">
                      <option v-for="productCategory in allProductCategory" :value="productCategory.id" :key='productCategory.id'>{{ productCategory.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('category_id')">
                      {{ errors.first("category_id") }}
                    </div>
                  </div>

                  <div class="form-group col-6">
                    <label class="control-label">Description</label>
                    <input type="text" id="description" name="description" v-model="value.description" class="form-control description" placeholder="Description">
                    <div class="help text-danger" v-show="errors.has('description')">
                      {{ errors.first("description") }}
                    </div>
                  </div>
                  <div class="form-group col-6 required">
                    <label class="control-label">Status</label>
                    <select class="form-control" style="width: 100%;" name="status" v-bind:class="{'has-error' : errors.has('status')}" v-validate="'required'" v-model="value.status" id="status">
                      <option :value="1">Active</option>
                      <option :value="0">Inactive</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('status')">
                      {{ errors.first("status") }}
                    </div>
                  </div>
                  <div class="form-group col-6 required">
                    <label class="control-label">App-id</label>
                    <select id="app_id" v-model="value.app_id" v-bind:class="{'has-error' : errors.has('app_id')}" v-validate="'required'" class="form-control">
                      <option v-for="application in allApplications" :value="application.app_id" :key='application.app_id'>{{ application.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('app_id')">
                      {{ errors.first("app_id") }}
                    </div>
                  </div>
                </div>

                <div class="has-many col-12">
                  <h4 class="site-heading my-3"><span>Product Property</span></h4>
                  <div class="form-row align-items-end" v-for="(product_property, index) in value.product_property" :key="index">
                    <div class="form-group col-4 required">
                      <label class="control-label">Name</label>
                      <input type="text" id="product_property.name" name="product_property.name" v-bind:class="{'has-error' : errors.has('product_property.name')}" v-validate="'required'" v-model="product_property.name" class="form-control product_property.name" placeholder="name">
                      <div class="help text-danger" v-show="errors.has('product_property.name')">
                        {{ errors.first("product_property.name") }}
                      </div>
                    </div>
                    <div class="form-group col-4 required">
                      <label class="control-label">Value</label>
                      <input type="text" id="product_property.value" name="product_property.value" v-bind:class="{'has-error' : errors.has('product_property.value')}" v-validate="'required'" v-model="product_property.value" class="form-control product_property.value" placeholder="value">
                      <div class="help text-danger" v-show="errors.has('product_property.value')">
                        {{ errors.first("product_property.value") }}
                      </div>
                    </div>
                    <div class="form-group col-4 required">
                      <label class="control-label">Status</label>
                      <select class="form-control" style="width: 100%;" id="product_property.status" name="product_property.status" v-bind:class="{'has-error' : errors.has('product_property.status')}" v-validate="'required'" v-model="product_property.status">
                        <option :value="1">Active</option>
                        <option :value="0">Inactive</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('product_property.status')">
                        {{ errors.first("product_property.status") }}
                      </div>
                    </div>
                    <div class="form-group col-12 border-bottom pb-3">
                      <button type="button" class="btn btn-danger float-right" @click="deleteProduct_property(index)"><i class="fa fa-trash"></i> Remove</button>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button type="button" @click="addProduct_property" class="btn btn-success"><i class="fa fa-plus"></i> New </button>
                  </div>
                </div>

                <div class="has-many col-12">
                  <h4 class="site-heading my-3"><span>Product Association</span></h4>
                  <div class="form-row align-items-end" v-for="(product_association, index) in value.product_association" :key="index">
                    <div class="form-group col-6 required">
                      <label class="control-label">Type</label>
                      <select class="form-control" style="width: 100%;" id="product_association.type" name="product_association.type" v-bind:class="{'has-error' : errors.has('product_association.type')}" v-validate="'required'" v-model="product_association.type">
                        <option value="Self">Self</option>
                        <option value="Component">Component</option>
                        <option value="Complement">Complement</option>
                        <option value="Incompatible">Incompatible</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('product_association.type')">
                        {{ errors.first("product_association.type") }}
                      </div>
                    </div>
                    <div class="form-group col-6 required">
                      <label class="control-label">Associated Product</label>
                      <select id="product_association.associated" name="product_association.associated" v-bind:class="{'has-error' : errors.has('product_association.associated')}" v-validate="'required'" v-model="product_association.associated" class="form-control">
                        <option v-for="product in allProducts" :value="product.id" :key='product.id'>{{ product.name }}</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('product_association.associated')">
                        {{ errors.first("product_association.associated") }}
                      </div>
                    </div>
                    <div class="form-group col-6 required">
                      <label class="control-label">Quantitye</label>
                      <input type="number" id="product_association.quantity" name="product_association.quantity" v-bind:class="{'has-error' : errors.has('product_association.quantity')}" v-validate="'required'" v-model="product_association.quantity" class="form-control product_association.quantity" placeholder="Quantitye">
                      <div class="help text-danger" v-show="errors.has('product_association.quantity')">
                        {{ errors.first("product_association.quantity") }}
                      </div>
                    </div>
                    <div class="form-group col-6 required">
                      <label class="control-label">Status</label>
                      <select class="form-control" style="width: 100%;" id="product_association.status" name="product_association.status" v-bind:class="{'has-error' : errors.has('product_association.status')}" v-validate="'required'" v-model="product_association.status">
                        <option :value="1">Active</option>
                        <option :value="0">Inactive</option>
                      </select>
                      <div class="help text-danger" v-show="errors.has('product_association.status')">
                        {{ errors.first("product_association.status") }}
                      </div>
                    </div>

                    <div class="form-group col-md-12 border-bottom pb-3">
                      <button type="button" class="btn btn-danger float-right" @click="deleteProduct_association(index)"><i class="fa fa-trash"></i> Remove</button>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button type="button" @click="addProduct_association" class="btn btn-success"><i class="fa fa-plus"></i> New
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { CBSProductApi, CBSCommonApi } from '@/services/api';

export default {
  name: 'ProductForm',
  components: {
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {
      type: 'Product',
      status: 1,
      product_property: [],
      product_association: [],
    },
    allApplications: [],
    allProductCategory: [],
    allProducts: [],
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSProductApi.updateProduct(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'ProductList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSProductApi.storeProduct(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$toaster.success(response.message);
                  this.$router.push({ name: 'ProductList' });
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          }
        }
      });
    },
    addProduct_property: function () {
      this.value.product_property.push({});
    },
    addProduct_association: function () {
      this.value.product_association.push({});
    },

    deleteProduct_property: function (index) {
      this.value.product_property.splice(index, 1)
    },
    deleteProduct_association: function (index) {
      this.value.product_association.splice(index, 1)
    },

    getDetail: function (id) {
      CBSProductApi.showProduct(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getApplications: function () {
      CBSCommonApi.getAllApplications().then((response) => {
        this.allApplications = response
      })
    },
    getProductCategory: function () {
      CBSCommonApi.getAllProductCategory().then((response) => {
        this.allProductCategory = response
      })
    },
    getProducts: function () {
      CBSCommonApi.getAllProducts().then((response) => {
        this.allProducts = response
      })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      }
      await this.getApplications()
      await this.getProductCategory()
      await this.getProducts()
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
